import React, { useEffect } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TransferSvg from "../../assets/transfer_pending.svg";
import { useSelector } from "react-redux";
import { amountRoundOff, convertToDateWithTime, triggerHaptic } from "../../helper/helper";
import { SupportButton } from "../support";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { isWeb } from "../../utils/platform";
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { fadeInAnimation, slideUpAnimation, pulseAnimation, buttonTransition } from "../../styles/animations";

const TransactionComplete = () => {
  const user = useSelector((state) => state.userAuth);
  const transaction = useSelector((state) => state.transaction);
  const navigate = useNavigate();
  const { fetchUser } = user;

  useEffect(() => {
    if (!transaction.sendPayment) {
      navigate("/transaction");
    }
  }, [transaction.sendPayment, navigate]);

  useEffect(() => {
    const handleTransactionComplete = async () => {
      if (!transaction.sendPayment?.message) {
        await triggerHaptic('heavy');
      }
    };
    handleTransactionComplete();
  }, [transaction.sendPayment?.message]);

  const parseMessage = (error) => {
    switch (error) {
      case "Bank details not verified.":
        return "The transaction failed because the recipient's bank details are invalid, or you entered an NRE bank account.";

      case "Financial institution did not respond":
        return "Your bank is not responding, please try again after some time. If the problem persists then write to us at care@crobo.money.";
 
      case "Insufficient funds":
        return "Your account balance is low";

      case "Transaction failed due to low balance in the source bank.":
        return "Your bank account is suspended, please contact your bank for more information.";
      default:
        if(error.trim().split(/\s+/).length > 4){
          return error;
        }
        return "Please try again after sometime. If the problem persists then write to us at care@crobo.money.";
    }
  };

  // Added Contact Support Button 
  return (
    <CroboContainer>
      <Container maxWidth="sm">
        <Card
          sx={{
            borderRadius: 3,
            mb: 3,
            overflow: "hidden",
            ...fadeInAnimation
          }}
        >
          {/* Status Banner */}
          <Box
            sx={{
              bgcolor: transaction.sendPayment?.message
                ? "error.light"
                : "brand.main",
              py: 2,
              px: 3,
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              color="white"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              {transaction.sendPayment?.message ? (
                <>
                  <ErrorOutlineIcon />
                  Transaction Failed
                </>
              ) : (
                <>
                  <CheckCircleOutlineIcon />
                  Transfer Success
                </>
              )}
            </Typography>
          </Box>

          {/* Image and Content */}
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 3,
                ...slideUpAnimation
              }}
            >
              <img
                src={TransferSvg}
                alt="transfer status"
                style={{
                  height: "180px",
                  maxWidth: "100%",
                }}
              />
            </Box>

            {transaction.sendPayment && transaction?.sendPayment?.message ? (
              <Typography
                textAlign="center"
                color="black"
                sx={{
                  p: 2,
                  borderRadius: 2,
                  fontSize: "1rem",
                  lineHeight: 1.5,
                }}
              >
                {parseMessage(transaction?.sendPayment?.message)}
              </Typography>
            ) : (
              <Box>
                {/* Recipient Info */}
                <Box
                  sx={{
                    mb: 3,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    Money sent to
                  </Typography>
                  <Typography variant="h6" fontWeight={600}>
                    {fetchUser.name}
                  </Typography>
                </Box>

                {/* Amount Details */}
                <Box
                  sx={{
                    bgcolor: "grey.50",
                    borderRadius: 2,
                    p: 2,
                    mb: 3,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        gutterBottom
                      >
                        You Sent
                      </Typography>
                      <Typography variant="h5" fontWeight={600}>
                        $
                        {amountRoundOff(
                          transaction?.sendPayment?.base_sub_total
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        gutterBottom
                      >
                        They Receive
                      </Typography>
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        color="brand.main"
                      >
                        ₹
                        {amountRoundOff(
                          transaction?.sendPayment?.base_total_amount
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                {/* Transfer Time */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    color: "text.secondary",
                  }}
                >
                  <AccessTimeIcon fontSize="small" />
                  <Typography variant="body2">
                    {convertToDateWithTime(
                      transaction?.sendPayment?.transactional_time
                    )} UTC
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Card>

        {/* Action Buttons */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...slideUpAnimation }}>
          <Link to="/transaction/history" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              sx={{
                py: 1.5,
                bgcolor: "brand.main",
                "&:hover": {
                  bgcolor: "brand.dark",
                },
                ...buttonTransition
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: "white",
                }}
              >
                View Transaction History
              </Typography>
            </Button>
          </Link>

          <SupportButton
            variant="outlined"
            fullWidth
            size="large"
            sx={{
              py: 1.5,
              borderColor: "brand.main",
              color: "brand.main",
              "&:hover": {
                borderColor: "brand.dark",
                bgcolor: "transparent",
              },
              ...buttonTransition
            }}
          />
        </Box>
      </Container>
    </CroboContainer>
  );
};

export default TransactionComplete;
